<template>
  <div class="test-result">
    <div class="back-btns">
      <button
        v-if="!viewMode"
        @click="backToList"
        class="btn-img mt-2">
        <img src="@/assets/icon-back.png"/>
      </button>
    </div>
    <div class="test-title">
      Result
      <div class="date">
        {{result.testDate}}
      </div>
    </div>
    <div class="summary">
      <div class="text">
        <h2>Lesson {{result.testSort}} {{result.testName}}</h2>
        <div>
          <h2>Points： {{result.score}}/{{result.totalScore}}</h2>
          <h2 class="ml-4">{{result.grade}}</h2>
          <h2 class="ml-4">名前：{{result.username}}</h2>
        </div>
        <div>
          <h2>Correct： {{result.rightAnswerNumber}}</h2>
          <h2 class="ml-4">Incorrect： {{result.wrongAnswerNumber}}</h2>
          <h2 class="ml-4">Skip： {{result.unknownAnswerNumber}}</h2>
        </div>
        <div class="single-results">
            <table>
              <tr>
                <td>Question No.</td>
                <td v-for="q in result.questionResults" :key="q.id">
                  {{q.id}}
                </td>
              </tr>
              <tr>
                <td>Your Results</td>
                <td v-for="q in result.questionResults" :key="q.id">
                  {{q.result}}
                </td>
              </tr>
            </table>
        </div>
        <div class="details">
          <h2>Details</h2>
        </div>
      </div>
      <div class="answer">
        <div class="percentage">{{$filters.percentage(correctRate)}}%</div>
        <div>
          <a :href="result.descriptionUrl" target="_blank">
            <img src="@/assets/icon-description.png" />
          </a>
        </div>
      </div>
    </div>
    <div
      v-html="result.evaluation"
      class="align-left border font-size-18">
    </div>
    <div class="align-left"><h2>Advice</h2></div>
    <div
      v-html="result.advice"
      class="align-left border font-size-18"></div>
    <div
      class="align-left"><h2>Study Curriculum</h2></div>
    <div
      class="border flex align-items-center">
        <div class="flex-1 align-left font-size-16">
          <div>
            <span>教科書ページ</span>:
            <span>{{result.textbookPage}}</span>
          </div>
          <div>
            <span>練習プログラム</span>:
            <span>{{result.recommendAppName}}</span>
          </div>
        </div>
        <div v-if="!viewMode">
          <div v-if="result.hasRecommendApp"
            @click="recommendApp"
            class="start-btn ml-2">
            Start
          </div>
          <div v-else class="flex">
            <div
              @click="freeApp"
              class="start-btn">
              Free Trial
            </div>
            <div
              @click="recommendApp"
              class="start-btn ml-2">
              Buy license
            </div>
          </div>
        </div>
        <div
            v-if="viewMode"
            @click="print"
            class="start-btn mt-2 mb-2">Print</div>
      </div>
      <div
        v-if="!viewMode"
        @click="print"
        class="btn-bg mt-2 mb-2">Print</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: { },
  name: 'LessonTestResult',
  data() {
    return {
      viewMode: false,
      result: {},
    }
  },
  computed: {
    ...mapState(['auth',]),
    correctRate()  {
      const t = this.result
      return t.rightAnswerNumber / (t.rightAnswerNumber + t.wrongAnswerNumber + t.unknownAnswerNumber)
    },
    prontestSeriesUrl() {
      return process.env.VUE_APP_SERIES_URL
    },
  },
  created() {
    const testHistoryId = this.$route.params.testHistoryId
    const encryptTestHistoryId = this.$route.query.id
    const mode = this.$route.query.mode
    if (mode === 'view') this.viewMode = true
    if (testHistoryId) {
      this.$api.get(`/testResult/lesson/${testHistoryId}`).then(res => {
        this.result = res.data
      })
    } else if (encryptTestHistoryId) {
      this.viewMode = true
      this.$api.get(`/testResult/noAuth/lesson?id=${encodeURIComponent(encryptTestHistoryId)}`).then(res => {
        this.result = res.data
      })
    }
  },
  methods: {
    backToList() {
      this.$router.push('/menu')
    },
    startExam(id) {
      this.$router.push(`/lesson/${id}/exam`)
    },
    showResults() {
      console.log('startDate:' + this.startDate)
      console.log('endDate:' + this.endDate)
    },
    setStartDate(v) {
      this.startDate = v
    },
    setEndDate(v) {
      this.endDate = v
    },
    print() {
      window.print()
    },
    freeApp() {
      const pageUrl = encodeURIComponent(`purchase.html?id=${this.result.recommendFreeAppId}`)
      location.href = `${this.prontestSeriesUrl}redirect.html?refreshToken=${this.auth.refreshToken}&page=${pageUrl}`
    },
    recommendApp() {
      const pageUrl = encodeURIComponent(`purchase.html?id=${this.result.recommendAppId}`)
      location.href = `${this.prontestSeriesUrl}redirect.html?refreshToken=${this.auth.refreshToken}&page=${pageUrl}`
    },
  }
}
</script>

<style lang="scss" scoped>
@media print {
  .btn-bg {
    display: none;
  }
}
.test-result {
  background: white;
  margin: auto;
  .back-btns {
    text-align: right;
  }
  h2 {
    margin: 5px 0;
    display: inline-block;
    font-size: 22px;
  }
  .ml-4 {
    margin-left: 30px;
  }
  .summary {
    display: flex;
    font-size: 25px;
    .text {
      flex: 5;
      text-align: left;
      .details {
        display: flex;
        align-items: flex-end;
        margin-bottom: 10px;
        h2 {
          margin-bottom: 0;
        }
      }
      .single-results {
        table {
          border-collapse: collapse;
          margin-left: 10px;
          font-size: 20px;
          td {
            border: 1px solid;
            padding: 2px 10px;
          }
        }
      }
    }
    .answer {
      margin: 15px;
      margin-top: 20px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 25px;
      font-weight: bold;
      .percentage {
        font-size: 49px;
      }
      img {
        height: 60px;
      }
    }
  }
  .border {
    border: 1px solid rgb(189, 183, 183);
    padding: 5px 15px;
  }
  .flex-1 {
    flex: 1;
  }
  .align-items-center {
    align-items: center;
  }
  .start-btn {
    margin: 5px 30px;
    padding: 5px 30px;
    border: 1px solid rgb(189, 183, 183);
    border-radius: 30px;
    font-size: 25px;
    cursor: pointer;
  }
  .test-title {
    text-align: center;
    font-size: 38px;
    .date {
      position: relative;
      margin-top: -40px;
      font-size: 28px;
      text-align: right;
      padding-right: 30px;
    }
  }
  .font-size-20 {
    font-size: 20px;
    color: #707070;
  }
  .font-size-18 {
    font-size: 18px;
    color: #707070;
  }
  .font-size-16 {
    font-size: 16px;
    color: #707070;
  }
  .textbook {
    font-size: 35px;
    color: #707070;
  }
}
</style>

